import { Typography } from '@mui/material';
import { TextOnPageProps } from 'src/features/@types/types';

// -------------------------------------------------------------------------------------------------

export default function TextOnPage({ title, align, mb, mt, color }: TextOnPageProps) {
  return (
    <Typography
      gutterBottom
      variant="h4"
      align={align || 'center'}
      mt={mt || 6}
      mb={mb || 3}
      color={color || 'initial'}
    >
      {title}
    </Typography>
  );
}
