import { ElementType, Suspense, lazy } from 'react';
import CustomLoadingScreen from 'src/features/components/loadingScreen';

// ------------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<CustomLoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ------------------------------------------------------------------------

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));
export const HomePage = Loadable(lazy(() => import('../features/pages/home')));
export const ComingSoonPage = Loadable(lazy(() => import('../pages/ComingSoonPage')));
export const MaintenancePage = Loadable(lazy(() => import('../pages/MaintenancePage')));

export const CursoDireito = Loadable(lazy(() => import('../features/pages/cursos/direito/index')));
export const ResponsabilidadeSocialPage = Loadable(
  lazy(() => import('../features/pages/cursos/direito/index'))
);

export const CursoEnfermagem = Loadable(
  lazy(() => import('../features/pages/cursos/enfermagem/index'))
);

export const CursoPedagogia = Loadable(
  lazy(() => import('../features/pages/cursos/pedagoria/index'))
);

export const PosGraudate = Loadable(lazy(() => import('../features/pages/posgaduacao/index')));

export const SaudeSeguranca = Loadable(
  lazy(() => import('../features/pages/posgaduacao/cursos/saudeSeguranca/index'))
);

export const SaudeFamilia = Loadable(
  lazy(() => import('../features/pages/posgaduacao/cursos/saudeFamilia/index'))
);

export const DireitoTrabalho = Loadable(
  lazy(() => import('../features/pages/posgaduacao/cursos/direitoTrabalho/index'))
);

export const Vestibular = Loadable(lazy(() => import('../features/pages/vestibular/index')));
export const InscricaoVestibular = Loadable(
  lazy(() => import('../features/pages/vestibular/inscricao/index'))
);
export const AboutUs = Loadable(lazy(() => import('../features/pages/about-us/index')));
export const Documentos = Loadable(lazy(() => import('../features/pages/documentacao/index')));
export const ContactUs = Loadable(lazy(() => import('../features/pages/contact-us/index')));
export const CpaPage = Loadable(lazy(() => import('../features/pages/cpa/index')));
export const BibliotecaPage = Loadable(lazy(() => import('../features/pages/biblioteca/index')));
export const NpjPage = Loadable(lazy(() => import('../features/pages/npj')));

export const Impensa = Loadable(lazy(() => import('../features/pages/imprensa/index')));

export const FestaJunina = Loadable(
  lazy(() => import('../features/pages/noticias/festaJunina/index'))
);

export const VadeMecum = Loadable(lazy(() => import('../features/pages/noticias/vadeMecum/index')));

export const Citologia = Loadable(
  lazy(() => import('../features/pages/noticias/aulaCitologia/index'))
);

export const AulaBioquimica = Loadable(
  lazy(() => import('../features/pages/noticias/aulaBioquimica/index'))
);

export const CerimoniaJaleco = Loadable(
  lazy(() => import('../features/pages/noticias/cerimôniaJaleco/index'))
);

export const Intramuscular = Loadable(
  lazy(() => import('../features/pages/noticias/intramuscular/index'))
);

export const Vacinacao = Loadable(lazy(() => import('../features/pages/noticias/vacinacaoOAB')));

export const Tribunal = Loadable(lazy(() => import('../features/pages/noticias/tribunal/index')));

export const InteracaoComunitaria = Loadable(
  lazy(() => import('../features/pages/noticias/interacaoComunitaria/index'))
);
