import { PATH_COURSES, PATH_NEWS, ROOTS_NEWS } from 'src/routes/paths';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Home',
    icon: <Iconify icon="tabler:home-filled" />,
    path: '/',
  },
  {
    title: 'Vestibular',
    icon: <Iconify icon="healthicons:i-exam-multiple-choice" />,
    path: '/vestibular',
  },
  {
    title: 'Graduação',
    path: '/graduacao',
    icon: <Iconify icon="ri:graduation-cap-fill" />,
    children: [
      {
        subheader: '',
        items: [
          { title: 'Direito', path: PATH_COURSES.direito },
          { title: 'Enfermagem', path: PATH_COURSES.enfermagem },
          { title: 'Pedagogia', path: PATH_COURSES.pedagogia },
        ],
      },
    ],
  },
  {
    title: 'Pós-Graduação',
    icon: <Iconify icon="fluent:hat-graduation-sparkle-16-filled" />,
    path: '/pos-graduacao',
  },
  {
    title: 'Noticias/Eventos',
    icon: <Iconify icon="bxs:news" />,
    path: PATH_NEWS.root,
  },

  {
    title: 'Institucional',
    path: '/institucional',
    icon: <Iconify icon="mdi:about" />,
    children: [
      {
        subheader: '',
        items: [
          { title: 'Quem Somos', path: '/quem-somos' },
          { title: 'Núcleo de Práticas Jurídicas - NPJ', path: '/npj' },
          { title: 'Comissão Própria de Avaliação - CPA', path: '/cpa' },
          {
            title: 'Documentos Institucionais',
            path: '/documentos-institucionais',
          },
          {
            title: 'Biblioteca',
            path: '/biblioteca',
          },
        ],
      },
    ],
  },

  {
    title: 'Fale Conosco',
    icon: <Iconify icon="ic:round-phone" />,
    path: '/fale-conosco',
  },
];

export default navConfig;
